export const ROUTE_NAMES = {
  SignIn: "SignIn",
  ValidateEmail: "ValidateEmail",
  ValidateEmailProduct: "ValidateEmailProduct",
  ForgetPassword: "ForgetPassword",
  DefinePassword: "DefinePassword",
  ResetPassword: "ResetPassword",
  SignUp: "SignUp",
  RegisterUser: "RegisterUser",
  RegisterUserDetails: "RegisterUserDetails",
  RegisterActivity: "RegisterActivity",
  RegisterActivityDetails: "RegisterActivityDetails",
  RegisterBank: "RegisterBank",
  ValidateBI: "ValidateBI",
  Dashboard: "Dashboard",
  Transactions: "Transactions",
  RentalBuildings: "RentalBuildings",
  SciIncome: "SciIncome",
  TaxDeclaration2065: "TaxDeclaration2065",
  TaxDeclaration2033: "TaxDeclaration2033",
  TaxDeclaration2031: "TaxDeclaration2031",
  PartnersIncome: "PartnersIncome",
  EventsGeneralAssembly: "EventsGeneralAssembly",
  EventsGeneralAssemblyDetails: "EventsGeneralAssemblyDetails",
  EventsYearEnd: "EventsYearEnd",
  Documents: "Documents",
  NewDocuments: "NewDocuments",
  SupportingDocuments: "SupportingDocuments",
  AccountingDashboard: "AccountingDashboard",
  AccountingDocuments: "AccountingDocuments",
  AccountingBalance: "AccountingBalance",
  AccountingOperations: "AccountingOperations",
  AccountingResult: "AccountingResult",
  AccountingBalanceSheetRecovery: "AccountingBalanceSheetRecovery",
  AccountingBalanceSheet: "AccountingBalanceSheet",
  AccountingCarryForward: "AccountingCarryForward",
  User: "User",
  Subscriptions: "Subscriptions",
  SubscriptionDetails: "SubscriptionDetails",
  SubscriptionDetailsContinue: "SubscriptionDetailsContinue",
  RealEstate: "RealEstate",
  RealEstateLMNP: "RealEstateLMNP",
  RentalAgreementFlow: "RentalAgreementFlow",
  Indexation: "Indexation",
  Regularization: "Regularization",
  RegularizationHistory: "RegularizationHistory",
  RentalUnit: "RentalUnit",
  RealEstateLoan: "RealEstateLoan",
  RentalAgreement: "RentalAgreement",
  BankAccounts: "BankAccounts",
  BankAccountDetails: "BankAccountDetails",
  BankAccountDetailsActivity: "BankAccountDetailsActivity",
  Activities: "Activities",
  ActivityCreate: "ActivityCreate",
  ActivitySubscriptionCreate: "ActivitySubscriptionCreate",
  ActivityContinue: "ActivityContinue",
  ActivityCreateCancel: "ActivityCreateCancel",
  ActivityDetail: "ActivityDetail",
  Tasks: "Tasks",
  RentalAgreements: "RentalAgreements",
  Tenants: "Tenants",
  Amortisations: "Amortisations",
  FixedAsset: "FixedAsset",
  FixedAssets: "FixedAssets",
  MyBusiness: "MyBusiness",
  RealEstateAssets: "RealEstateAssets",
  Error404: "Error404",
  Fec: "fec",
  PartnersArea: "PartnersArea",
  ModelsLibrary: "ModelsLibrary",
  Accruals: "Accruals",
};
