import { model, Schema, Document } from "mongoose";
import { ulid } from "ulid";
import { BankAccount, PartialField } from "..";

export type BankAccountStatsStockBankAccounts = BankAccount;

export interface BankAccountStats {
  id: string;
  stockBankAccounts: {
    total: number;
    sync: number;
  };
  date: string;
}
export type BankAccountStatsUpsert = PartialField<BankAccountStats, "id">;

const bankAccountStatsSchema = new Schema<BankAccountStatsDocument>(
  {
    _id: { type: String, default: () => ulid() },
    stockBankAccounts: {
      total: { type: Number },
      sync: { type: Number },
    },
    date: { type: String, require: true, index: true, unique: true },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: BankAccountStatsDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type BankAccountStatsDocument = BankAccountStats & Document<string>;

export const BankAccountStatsModel = model<BankAccountStatsDocument>(
  "BankAccountStats",
  bankAccountStatsSchema,
  "BankAccountsStats"
);
