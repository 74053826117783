


















































































import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { productsStore, rentalAgreementsStore } from "@/store";
import {
  AnomalyError,
  AnomalyErrorResult,
  AnomalyOptions,
  CheckNewAnomaliesType,
  JournalComposedEntryWithTransaction,
  Transaction,
  TypeReference,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { useTransactions } from "../transactions/transactions.usable";
import { useAnomalies } from "./anomalies.usable";

export interface AnomaliesView {
  getAnomalies: (params: {
    transactions?: Transaction[];
    options?: AnomalyOptions;
  }) => Promise<AnomalyErrorResult[]>;
}

/**
 * @Component TransactionsAnomalies
 * @prop {String<"transaction" | "functional">} type - required
 * @prop {Transaction[]} transactions - require if options is undefined
 * @prop {AnomalyOptions} options - require if transactions is undefined
 */
export default defineComponent({
  name: "Anomalies",
  props: {
    type: {
      type: String as PropType<"transaction" | "functional">,
      required: true,
    },
    fromFunctional: {
      type: String as PropType<TypeReference>,
      required: false,
    },
    checkNewAnomaliesType: {
      type: String as PropType<CheckNewAnomaliesType>,
      required: false,
    },
    transactions: {
      type: Array as PropType<Transaction[]>,
      required: false,
    },
    options: {
      type: Object as PropType<AnomalyOptions>,
      required: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { goTransactionAnomaliesFilter } = useTransactions();
    const { checkOnNewAnomalies, checkDisableAnomalies } = useAnomalies();

    /**
     * Data
     */
    const anomaliesList: Ref<
      {
        anomaly: AnomalyErrorResult;
        operations: JournalComposedEntryWithTransaction[];
        message: string;
      }[]
    > = ref([]);
    const isOpenModal = computed(
      () => anomaliesList.value.length && props.openModal
    );

    // A list of anomalies that are displayed in the functional anomalies list.
    const showFunctionalAnomalies = [
      // TVA
      AnomalyError.taxTvaEnableRequired,
      AnomalyError.taxTvaLedgerAccount445720Required,
      // Loan
      AnomalyError.loanInsuranceEnableRequired,
      AnomalyError.loanLedgerAccount616600Required,
      AnomalyError.loanRepaymentDeadlineAmountEqualTransactionAmountRequired,
      AnomalyError.loanRepaymentDeadlineDateEqualTransactionDateRequired,
      // Asset
      AnomalyError.realEstateAssetNotSameAcquisitionAmount,
      AnomalyError.realEstateAssetNotSameBoughtFeeAmount,
    ];

    const isEnableButtonOnTransactionType = computed(() =>
      showFunctionalAnomalies.includes(
        anomaliesList.value[0]?.anomaly.anomalyError
      )
    );

    const redirectToFunctionalOnFunctionalType = [
      // Object id link
      AnomalyError.objectIdLinkRentalUnitRequired,
    ];

    /**
     * Actions
     */
    // A function that is called when the component is mounted. It is used to get the anomalies of the transactions.
    const getAnomalies = async ({
      transactions = props.transactions,
      options = props.options,
    }) => {
      const { anomaliesErrorsSortByPriority, operationsErrors } =
        await checkOnNewAnomalies({
          checkNewAnomaliesType: props.checkNewAnomaliesType,
          transactions,
          options,
        });

      anomaliesList.value = anomaliesErrorsSortByPriority
        .filter((anomaly) => checkDisableAnomalies(anomaly.anomalyError))
        .map((anomaly) => {
          return {
            anomaly: anomaly,
            operations: operationsErrors.filter(
              (operation) =>
                !!operation.journalEntry.lines?.filter(
                  (line) => !!line.anomalies?.includes(anomaly)
                )
            ),
            message: context.root
              .$t(
                `anomaliesError.${props.type}${
                  anomaly.fromReferenceType === props.fromFunctional
                    ? "-to"
                    : ""
                }-${anomaly.anomalyError}`
              )
              .toString(),
          };
        });

      return anomaliesErrorsSortByPriority;
    };

    // A function that is called when the user clicks on the button "Voir le/les transactions(s)" in the
    // component TransactionsAnomalies. It is used to get the transactions ids of the transactions that
    // have the anomaly.
    const goTransactionOrFunctionalAnomalies = () => {
      if (
        redirectToFunctionalOnFunctionalType.includes(
          anomaliesList.value[0].anomaly.anomalyError
        )
      ) {
        if (
          anomaliesList.value[0].anomaly.fromReferenceType ===
            TypeReference.rentalAgreement &&
          anomaliesList.value[0].anomaly.fromReferenceId
        ) {
          const rentalAgreement = rentalAgreementsStore.rentalAgreements.find(
            (rentalAgreement) =>
              rentalAgreement.id ===
              anomaliesList.value[0].anomaly.fromReferenceId
          );
          if (props.fromFunctional === TypeReference.rentalAgreement) {
            if (
              anomaliesList.value[0].anomaly.toReferenceType ===
                TypeReference.rentalUnit &&
              anomaliesList.value[0].anomaly.toReferenceId &&
              rentalAgreement?.product?.realEstateAsset?.id
            ) {
              router.push({
                name: ROUTE_NAMES.RentalUnit,
                params: {
                  productId: productsStore.currentId,
                  realEstateAssetId: rentalAgreement.product.realEstateAsset.id,
                  rentalUnitId: anomaliesList.value[0].anomaly.toReferenceId,
                },
              });
            }
          } else {
            if (
              rentalAgreement?.product.realEstateAsset?.id &&
              anomaliesList.value[0].anomaly.fromReferenceId
            ) {
              router.push({
                name: ROUTE_NAMES.RentalAgreement,
                params: {
                  productId: productsStore.currentId,
                  realEstateAssetId: rentalAgreement.product.realEstateAsset.id,
                  rentalAgreementId:
                    anomaliesList.value[0].anomaly.fromReferenceId,
                },
              });
            }
          }
        }
      } else {
        const transactionsIds: string[] = [];
        transactionsIds.push(
          ...anomaliesList.value[0].operations.map(
            (operation) => operation.transactionId
          )
        );
        goTransactionAnomaliesFilter({
          anomaly: anomaliesList.value.length
            ? anomaliesList.value[0].anomaly.anomalyError
            : undefined,
          transactionsIds: transactionsIds.length ? transactionsIds : undefined,
        });
      }
    };

    // A function that is called when the user clicks on the button "Voir le paramétrage" in the
    // component TransactionsAnomalies. It is used to get the transactions ids of the transactions that
    // have the anomaly.
    const goFunctionalAnomalies = () => {
      if (
        showFunctionalAnomalies.includes(
          anomaliesList.value[0].anomaly.anomalyError
        )
      ) {
        for (const operation of anomaliesList.value[0].operations) {
          if (operation.journalEntry.lines) {
            for (const line of operation.journalEntry.lines) {
              if (
                line.anomalies?.find((anomaly) =>
                  showFunctionalAnomalies.includes(anomaly.anomalyError)
                )
              ) {
                if (
                  anomaliesList.value[0].anomaly.anomalyError ===
                    AnomalyError.taxTvaEnableRequired ||
                  anomaliesList.value[0].anomaly.anomalyError ===
                    AnomalyError.taxTvaLedgerAccount445720Required
                ) {
                  const realEstateAssetRef = line.refs?.find(
                    (ref) => ref.type === TypeReference.realEstateAsset
                  );
                  const rentalUnitRef = line.refs?.find(
                    (ref) => ref.type === TypeReference.rentalUnit
                  );
                  if (realEstateAssetRef && rentalUnitRef) {
                    router.push({
                      name: ROUTE_NAMES.RentalUnit,
                      params: {
                        productId: productsStore.currentId,
                        realEstateAssetId: realEstateAssetRef.referredId,
                        rentalUnitId: rentalUnitRef.referredId,
                      },
                    });
                  }
                }

                if (
                  anomaliesList.value[0].anomaly.anomalyError ===
                    AnomalyError.loanInsuranceEnableRequired ||
                  anomaliesList.value[0].anomaly.anomalyError ===
                    AnomalyError.loanLedgerAccount616600Required ||
                  anomaliesList.value[0].anomaly.anomalyError ===
                    AnomalyError.loanRepaymentDeadlineAmountEqualTransactionAmountRequired ||
                  anomaliesList.value[0].anomaly.anomalyError ===
                    AnomalyError.loanRepaymentDeadlineDateEqualTransactionDateRequired
                ) {
                  const realEstateAssetRef = line.refs?.find(
                    (ref) => ref.type === TypeReference.realEstateAsset
                  );
                  const realEstateLoantRef = line.refs?.find(
                    (ref) => ref.type === TypeReference.realEstateLoan
                  );
                  if (realEstateAssetRef && realEstateLoantRef) {
                    router.push({
                      name: ROUTE_NAMES.RealEstateLoan,
                      params: {
                        productId: productsStore.currentId,
                        realEstateAssetId: realEstateAssetRef.referredId,
                        realEstateLoanId: realEstateLoantRef.referredId,
                      },
                    });
                  }
                }

                if (
                  anomaliesList.value[0].anomaly.anomalyError ===
                    AnomalyError.realEstateAssetNotSameAcquisitionAmount ||
                  anomaliesList.value[0].anomaly.anomalyError ===
                    AnomalyError.realEstateAssetNotSameBoughtFeeAmount
                ) {
                  const realEstateAssetRef = line.refs?.find(
                    (ref) => ref.type === TypeReference.realEstateAsset
                  );
                  if (realEstateAssetRef) {
                    window.open(
                      router.resolve({
                        name: ROUTE_NAMES.RealEstate,
                        params: {
                          productId: productsStore.currentId,
                          realEstateAssetId: realEstateAssetRef.referredId,
                        },
                      }).href,
                      "_blank"
                    );
                  }
                }
              }
            }
          }
        }
      }
    };

    // A function that is called to retrieve alert type to display
    const getAlertType = computed(() => {
      if (
        anomaliesList.value[0].anomaly.anomalyError ===
          AnomalyError.realEstateAssetNotSameAcquisitionAmount ||
        anomaliesList.value[0].anomaly.anomalyError ===
          AnomalyError.realEstateAssetNotSameBoughtFeeAmount
      ) {
        return "warning";
      } else {
        return "error";
      }
    });

    // A function that is called when the user clicks on the button "Voir le/les transactions(s)" or "Voir
    // le paramétrage" in the component TransactionsAnomalies. It is used to get the transactions ids of
    // the transactions that have the anomaly.
    const goAnomalies = () => {
      props.type === "transaction"
        ? goFunctionalAnomalies()
        : props.type === "functional"
        ? goTransactionOrFunctionalAnomalies()
        : undefined;
    };

    /**
     * Init
     */
    const init = async () => {
      await getAnomalies({});
    };
    watch(
      () => props,
      () => init(),
      { deep: true }
    );
    onBeforeMount(async () => await init());

    /**
     * Return data
     */
    return {
      anomaliesList,
      isEnableButtonOnTransactionType,
      goAnomalies,
      getAnomalies,
      isOpenModal,
      getAlertType,
    };
  },
});
