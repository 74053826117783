






















































import { HelpingMessage } from "@/components/atom/helping";
import PageHeader from "@/components/atom/PageHeader.vue";
import AccrualsTable from "@/components/core/operations/accruals/AccrualsTable.vue";
import {
  accountingPeriodsStore,
  operationAccrualsStore,
  productsStore,
} from "@/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from "@vue/composition-api";
import DialogRight from "../../DialogRight.vue";
import Categories from "../categorization/Categories.vue";
import Categorization from "../categorization/Categorization.vue";
import AccrualCreate from "./create/AccrualCreate.vue";
import { useAccruals } from "./accruals.usable";

export default defineComponent({
  name: "AccrualsPage",
  components: {
    PageHeader,
    HelpingMessage,
    AccrualsTable,
    AccrualCreate,
    Categorization,
    Categories,
    DialogRight,
  },

  setup() {
    const {
      accrualItems,
      unreconciledAccrualItems,
      initAccrualItems,
      initUnreconciledAccrualItems,
    } = useAccruals();
    const isOpenAddAccrual = ref(false);

    const refreshItems = async () => {
      await initAccrualItems();
      await initUnreconciledAccrualItems();
    };

    watch(
      () => operationAccrualsStore.operationAccruals,
      (newVal, oldVal) => {
        if (newVal.length !== oldVal.length) {
          initAccrualItems();
        }
      }
    );
    watch(
      () => operationAccrualsStore.unreconciledOperationAccruals,
      (newVal, oldVal) => {
        if (newVal.length !== oldVal.length) {
          initUnreconciledAccrualItems();
        }
      }
    );
    watch(
      () => accountingPeriodsStore.currentId,
      async () => {
        await operationAccrualsStore.fetchOperationAccruals({
          productId: productsStore.currentId,
          accountingPeriodId: accountingPeriodsStore.currentId,
          options: { includedReconciled: true },
        });
      }
    );

    onBeforeMount(async () => {
      await operationAccrualsStore.fetchOperationAccruals({
        productId: productsStore.currentId,
        accountingPeriodId: accountingPeriodsStore.currentId,
        options: { includedReconciled: true },
      });
      await operationAccrualsStore.fetchUnreconciledOperationAccruals({
        productId: productsStore.currentId,
      });
      await initAccrualItems();
      await initUnreconciledAccrualItems();
    });

    return {
      isOpenAddAccrual,
      accrualItems: computed(() =>
        accrualItems.value.filter(
          (accrualItem) => !accrualItem.year.includes("Avant")
        )
      ),
      unreconciledAccrualItems,
      refreshItems,
    };
  },
});
