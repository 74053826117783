























































import PageHeader from "@/components/atom/PageHeader.vue";
import {
  LedgerAccountEnum,
  getReferredIdByTypeWithReferences,
  TaskTypeReference,
  AccountingBalanceSheet,
  AccountingPeriod,
} from "@edmp/api";
import {
  defineComponent,
  ref,
  onBeforeMount,
  PropType,
  computed,
} from "@vue/composition-api";
import AccrualsInventoryTab from "./AccrualsInventoryTab.vue";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  operationAccrualsStore,
  productsStore,
  tasksStore,
} from "@/store";
import {
  AccrualsInventoryState,
  useAccrualsInventory,
} from "./accrualsInventory.usable";
import { operationsService } from "@/services";
import { TaskLocal } from "@/models";

export default defineComponent({
  name: "AccrualsInventory",
  props: {
    task: {
      type: Object as PropType<TaskLocal>,
      required: true,
    },
  },
  components: {
    PageHeader,
    AccrualsInventoryTab,
  },
  setup(props, context) {
    const tabItemIndex = ref(0);
    const { initAccrualsInventoryState } = useAccrualsInventory();
    const accrualsInventoryStateClient = ref<AccrualsInventoryState>();
    const accrualsInventoryStateProvider = ref<AccrualsInventoryState>();

    const finish = async () => {
      if (!accrualsInventoryStateClient.value?.firstYear) {
        const clientLossIds =
          accrualsInventoryStateClient.value?.operationLossIds || [];

        if (clientLossIds.length) {
          await operationsService.accruals.reportLoss({
            ids: clientLossIds,
            accountingPeriodId: accountingPeriodsStore.currentId,
          });
        }
      }
      await tasksStore.validateTask({ taskLocal: props.task });
      context.emit("close");
    };

    onBeforeMount(async () => {
      await operationAccrualsStore.fetchOperationAccruals({
        productId: productsStore.currentId,
      });
      await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      const accountingPeriodId = getReferredIdByTypeWithReferences(
        props.task.references,
        TaskTypeReference.accountingPeriod
      );
      const accountingPeriod: AccountingPeriod =
        accountingPeriodsStore.accountingPeriods.find(
          (accountingPeriod) => accountingPeriod.id === accountingPeriodId
        ) as AccountingPeriod;
      const previousBalanceSheet = computed(
        () => accountingBalanceSheetsStore.getPreviousYearAccountingBalanceSheet
      );
      const currentBalanceSheet = computed(
        () =>
          accountingBalanceSheetsStore.getCurrentAccountingBalanceSheet as AccountingBalanceSheet
      );
      accrualsInventoryStateClient.value = initAccrualsInventoryState(
        accountingPeriod,
        LedgerAccountEnum.N411000,
        currentBalanceSheet.value,
        previousBalanceSheet.value
      );
      accrualsInventoryStateProvider.value = initAccrualsInventoryState(
        accountingPeriod,
        LedgerAccountEnum.N401000,
        currentBalanceSheet.value,
        previousBalanceSheet.value
      );
    });

    return {
      tabItemIndex,
      accrualsInventoryStateClient,
      accrualsInventoryStateProvider,
      finish,
    };
  },
});
