







































































































































































































































































































































































































import { documentsStore } from "@/store";
import {
  CategorizationEntry,
  Direction,
  Document,
  isPlanBankAccount,
  JournalEntryLine,
  OperationAccrualLib,
  OperationAccrualsModel,
} from "@edmp/api";
import { computed, defineComponent, PropType, Ref } from "@vue/composition-api";
import { useTransactions } from "../../transactions/transactions.usable";

export default defineComponent({
  name: "AccrualCard",
  props: {
    operationAccrual: {
      type: Object as PropType<OperationAccrualsModel.OperationAccrual>,
      required: true,
    },
    removable: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  setup(props, context) {
    const { getAttribute } = useTransactions();
    const documents: Ref<Document[]> = computed(() => documentsStore.documents);
    const getSupportingDocument = (documentId: string | undefined) => {
      const docId = documentId;
      const document: Document[] = documents.value.filter(
        ({ id }) => id === docId
      );
      return document[0].href;
    };
    const amount = computed(() =>
      OperationAccrualLib.getAmount(props.operationAccrual)
    );
    const formatLinesTransaction = (
      categories: JournalEntryLine[]
    ): CategorizationEntry[] =>
      categories
        .filter(
          (line: JournalEntryLine) =>
            !isPlanBankAccount(line.account) && line.account !== "512100"
        )
        .map((line: JournalEntryLine) => {
          const category: CategorizationEntry = {
            account: line.account,
            accountName: line.accountName,
            amount:
              line.direction === Direction.credit ? line.amount : -line.amount,
          };

          if (line.refs) {
            line.refs.forEach((ref) => (category[ref.type] = ref.referredId));
          }
          return category;
        });
    const categories = formatLinesTransaction(
      props.operationAccrual.journalEntry.lines || []
    );
    const computedAvatar = computed(() => {
      if (amount.value && amount.value >= 0) {
        return "#b8d7fb";
      } else {
        return "#7085d1";
      }
    });

    return {
      amount,
      categories,
      getAttribute,
      getSupportingDocument,
      computedAvatar,
      remove: () => context.emit("remove"),
    };
  },
});
