import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AccountingPeriod,
  AccountingPeriodCreateInternal,
  AccountingPeriodsService,
  AccountingPeriodUpdate,
  getMoment,
  TaxRegime,
} from "@edmp/api";
import { accountingsService } from "@/services";
import {
  accountingBalanceSheetsStore,
  accountingResultsStore,
  accountingsStore,
  productsStore,
  tasksStore,
  transactionsStore,
} from "@/store";
import { cloneDeep, findLast } from "lodash";

export interface AccountingPeriodsState {
  accountingPeriods: AccountingPeriodsService.ListOut;
  currentId: string;
  loading: boolean;
}

@Module({
  name: "accounting-periods-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class AccountingPeriodsStore
  extends VuexModule
  implements AccountingPeriodsState
{
  accountingPeriods: AccountingPeriodsService.ListOut = [];
  currentId = "";
  loading = false;

  get currentAccountingPeriod() {
    return this.accountingPeriods.find((acc) => acc.id === this.currentId);
  }

  get isIR() {
    return this.currentAccountingPeriod?.taxRegime === TaxRegime.IR_2072;
  }

  get isIS() {
    return this.currentAccountingPeriod?.taxRegime === TaxRegime.IS_2065;
  }

  get isLMNP() {
    return this.currentAccountingPeriod?.taxRegime === TaxRegime.LMNP_2031;
  }

  get getAccountingPeriods() {
    return (productId) =>
      this.accountingPeriods.filter((acc) => acc.productId === productId);
  }

  get lastAccountingPeriod() {
    const active = [...this.accountingPeriods.filter((acc) => !acc.closed)];
    active.sort((acc1, acc2) => getMoment(acc2.endAt).diff(acc1.endAt));
    return active[0];
  }

  get firstAccountingPeriod() {
    return cloneDeep(this.accountingPeriods).sort((acc1, acc2) =>
      getMoment(acc1.endAt).diff(acc2.endAt)
    )[0];
  }

  get lastAccountingPeriodNotClosed() {
    return cloneDeep(this.accountingPeriods).sort((acc1, acc2) =>
      getMoment(acc2.endAt).diff(acc1.endAt)
    )[0];
  }

  get firstAccountingPeriodNotClosed() {
    const active = [...this.accountingPeriods.filter((acc) => !acc.closed)];
    active.sort((acc1, acc2) => getMoment(acc1.endAt).diff(acc2.endAt));
    return active[0];
  }

  get previousAccountingPeriod() {
    const sortedPeriods = cloneDeep(this.accountingPeriods).sort((acc1, acc2) =>
      getMoment(acc2.endAt).diff(acc1.endAt)
    );
    const firstIndex = sortedPeriods.findIndex(
      (period) => period.endAt === this.currentAccountingPeriod?.endAt
    );
    return firstIndex !== -1 ? sortedPeriods[firstIndex + 1] : null;
  }

  @Mutation
  reset(): void {
    this.accountingPeriods = [];
    this.currentId = "";
    this.loading = false;
  }

  @Mutation
  setAccountingPeriods(accountingPeriods: AccountingPeriod[]): void {
    this.accountingPeriods = accountingPeriods;
  }

  @Mutation
  setCurrentId(id: string): void {
    this.currentId = id;
  }

  @Mutation
  addAccountingPeriod(accountingPeriod: AccountingPeriod): void {
    this.accountingPeriods = [...this.accountingPeriods, accountingPeriod];
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Action
  async switchAccountingPeriod(id: string) {
    this.setCurrentId(id);
    accountingResultsStore.getAccountingResult(id);

    // Refresh others store
    if (productsStore.currentId) {
      await Promise.all([
        transactionsStore.updateCategoriesList(),
        transactionsStore.fetchTransactions({
          productId: productsStore.currentId,
          accountingPeriodId: id,
        }),
        accountingsStore.fetchOperations(id),
      ]);
      void accountingBalanceSheetsStore.fetchAccountingBalanceSheets(); // ! For improvement front performance on startup
    }
  }

  @Action
  async selectFirstYearActive() {
    let accountingPeriodCurrentYear: AccountingPeriod | undefined;
    if (this.accountingPeriods.length > 0) {
      accountingPeriodCurrentYear = findLast(
        this.accountingPeriods,
        (acc) => !acc.closed
      );

      if (!accountingPeriodCurrentYear) {
        const now = getMoment();
        accountingPeriodCurrentYear = this.accountingPeriods.find(
          ({ startAt, endAt }) => now.isBetween(startAt, endAt, "day", "[]")
        );
      }
      if (accountingPeriodCurrentYear)
        await this.switchAccountingPeriod(accountingPeriodCurrentYear.id);
      else {
        await this.switchAccountingPeriod(
          this.accountingPeriods[this.accountingPeriods.length - 1].id
        );
      }
    }
  }

  @Action
  async fetchAccountingPeriods(productId: string): Promise<void> {
    this.setLoading(true);

    const ref = await accountingsService.periods.list({
      productId,
    });
    ref.sort(
      (acc1, acc2) =>
        getMoment(acc2.endAt).year() - getMoment(acc1.endAt).year()
    );
    this.setAccountingPeriods(ref);

    tasksStore.openYearEndModal();
    this.setLoading(false);
  }

  @Action
  async createAccountingPeriod(
    accountingPeriodCreate: AccountingPeriodCreateInternal
  ): Promise<void> {
    const newAcc = await accountingsService.periods.create(
      accountingPeriodCreate
    );
    this.addAccountingPeriod(newAcc);
    await this.selectFirstYearActive();
  }

  @Action
  async updateAccountingPeriod(
    accountingPeriodUpdate: AccountingPeriodUpdate
  ): Promise<void> {
    const acc = await accountingsService.periods.update(accountingPeriodUpdate);
    this.fetchAccountingPeriods(acc.productId);
    await this.switchAccountingPeriod(acc.id);
  }
}
