

























































import { Suggestion } from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  PropType,
} from "@vue/composition-api";

import { accountingPeriodsStore, productsStore } from "@/store";

import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { AccrualState } from "../accruals/accruals.usable";
import { useCategorization } from "./categorization.usable";
import { groupBy } from "lodash";

export default defineComponent({
  name: "Categories",
  props: {
    accrualState: {
      type: Object as PropType<AccrualState>,
      required: true,
    },
    categories: {
      type: Array as PropType<Suggestion[]>,
      required: false,
    },
  },
  setup(props, context) {
    const accrualState = computed(() => props.accrualState);
    const categorizationUse = computed(() =>
      useCategorization(accrualState.value, context)
    );
    const summary: ComputedRef = computed(() => {
      return accrualState.value.summary;
    });

    const getSuggestions = async () => {
      if (productsStore.currentId && accountingPeriodsStore.currentId) {
        await categorizationUse.value.updateCategoriesList();
      }
    };
    const clickCategory = (category: Suggestion) => {
      categorizationUse.value.selectCategory(category);
    };
    const closeCategorization = () => {
      categorizationUse.value.resetCategories();
      categorizationUse.value.isOpenCategorizationList.value = false;
    };

    onBeforeMount(getSuggestions);

    return {
      summary,
      suggestions: groupBy(props.categories, "parent"),
      clickCategory,
      closeCategorization,
      openArticleCategorizeAvailable: () =>
        useCrisp().openArticle(ArticleEnum.CATEGORIZE_AVAILABLE),
    };
  },
});
