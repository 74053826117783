import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/root";
import { accountingsService } from "@/services";
import { groupBy, Dictionary, flatMap, chain, head, omit } from "lodash";
import i18n from "@/plugins/i18n";
import {
  FilterKeys,
  FiltersParameters,
  filteredTransactions,
  Filters,
} from "@/utils";
import {
  LedgerAccountEnum,
  Suggestion,
  Transaction,
  TransactionType,
  TransactionsService,
  OperationAccrualLib,
} from "@edmp/api";
import { accountingPeriodsStore } from "..";
import { useAnomalies } from "@/components/core/anomalies/anomalies.usable";
import { transactionsService } from "@/services/Transactions.service";

const { checkDisableAnomalies } = useAnomalies();

export interface TransactionsState {
  transactions: Dictionary<Transaction>;
  filters: Filters;
  categoriesList: Dictionary<Suggestion[]>;
  loading: boolean;
}

@Module({
  name: "transactions-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class TransactionsStore extends VuexModule implements TransactionsState {
  transactions: Dictionary<Transaction> = {};
  filters: Filters = {};
  categoriesList: Dictionary<Suggestion[]> = {};
  loading = false;

  // /!\ Filters are apply for all (from accounting or CERFA..)
  get filteredTransactions(): Transaction[] {
    return filteredTransactions();
  }

  get transactionsGroupByDate(): Dictionary<Transaction[]> {
    return groupBy(flatMap(this.transactions), "date.operation");
  }

  get filteredTransactionsGroupByDate(): Dictionary<Transaction[]> {
    return chain(this.filteredTransactions)
      .orderBy("date.operation", "desc")
      .groupBy("date.operation")
      .value();
  }

  get transactionsGroupByMonth(): Dictionary<Transaction[]> {
    return groupBy(flatMap(this.transactions), (tr) => {
      const dateOp = new Date(tr.date.operation);
      return i18n.d(new Date(dateOp), "month-year", "fr-FR");
    });
  }

  get filteredTransactionsGroupByMonth(): Dictionary<Transaction[]> {
    return groupBy(this.filteredTransactions, (tr) => {
      const dateOp = new Date(tr.date.operation);
      return i18n.d(new Date(dateOp), "month-year", "fr-FR");
    });
  }

  get numberOfTransactionsToBeCategorized(): number {
    return flatMap(this.transactions).filter(
      (t) => !t.operations || t.operations?.journalEntry?.lines?.length == 0
    ).length;
  }

  get numberOfTransactionsToBeSuggested(): number {
    return flatMap(this.transactions).filter(
      (t) =>
        t.operations?.journalEntry?.lines?.length == 0 &&
        t.operations?.journalEntry.suggestedLines?.length != 0
    ).length;
  }

  get numberOfTransactionsToBeAnomalized(): number {
    return flatMap(this.transactions).filter(
      (t) =>
        !!t.operations?.journalEntry?.lines?.find(
          (line) =>
            !!line.anomalies?.filter((anomaly) =>
              checkDisableAnomalies(anomaly.anomalyError)
            ).length
        )
    ).length;
  }

  get sumOfFilteredTransactions(): number {
    return filteredTransactions().reduce(
      (acc: number, item) => acc + item.value.amount,
      0
    );
  }

  get atLeastOneCategorizedTransaction(): boolean {
    return flatMap(this.transactions).filter((t) => t.operations).length > 0;
  }

  @Mutation
  reset(): void {
    this.transactions = {};
    this.filters = {};
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  addFilter<Keys extends FilterKeys>({
    code,
    filterParams,
  }: {
    code: Keys;
    filterParams?: FiltersParameters[Keys];
  }): void {
    this.filters[code] = filterParams;
  }

  @Action
  setFilter<Keys extends FilterKeys>({
    code,
    filterParams,
  }: {
    code: Keys;
    filterParams?: FiltersParameters[Keys];
  }): void {
    this.addFilter({ code, filterParams });
  }

  @Mutation
  removeFilter(code: FilterKeys): void {
    this.filters = omit(this.filters, code);
  }

  @Action
  delFilter(code: FilterKeys): void {
    this.removeFilter(code);
  }

  @Mutation
  resetFilter(): void {
    this.filters = {};
  }

  @Action
  clearFilter(): void {
    this.resetFilter();
  }

  @Mutation
  setTransactions(transactions: Array<Transaction>): void {
    this.transactions = chain(transactions)
      .orderBy("date.operation", "desc")
      .groupBy("id")
      .mapValues(head)
      .omit()
      .value();
  }

  @Action
  async fetchTransactions(
    params: TransactionsService.ListPeriodIn
  ): Promise<void> {
    this.setLoading(true);
    this.setTransactions([]);
    try {
      const transactions = await transactionsService.listPeriod(params);
      this.setTransactions(transactions);
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  @Mutation
  setTransaction(transaction: Transaction): void {
    this.transactions[transaction.id] = transaction;
    // Trigger refresh
    this.transactions = { ...this.transactions };
  }

  @Mutation
  removeTransaction(transactionId: string): void {
    delete this.transactions[transactionId];
    // Trigger refresh
    this.transactions = { ...this.transactions };
  }

  @Action
  async fetchTransaction(params: TransactionsService.GetIn): Promise<void> {
    this.setLoading(true);

    try {
      const transaction = await transactionsService.get(params);
      this.setTransaction(transaction);
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  @Action
  async createTransaction(params: {
    productId: string;
    date: string;
    amount: number;
    summary: string;
    type: TransactionType;
  }): Promise<Transaction> {
    this.setLoading(true);
    try {
      const transaction = await transactionsService.create({
        productId: params.productId,
        date: { operation: params.date },
        summary: params.summary,
        value: { amount: params.amount, currency: "EUR" },
        type: params.type,
      });
      this.setTransaction(transaction);
      this.setLoading(false);
      return transaction;
    } catch (e) {
      console.error(e);
      this.setLoading(false);
      throw e;
    }
  }

  @Action
  async deleteTransaction(params: TransactionsService.DeleteIn): Promise<void> {
    const isDleted = await transactionsService.delete(params);
    if (isDleted) {
      this.removeTransaction(params.id);
    }
  }

  /**
   * List of all categories
   */
  @Mutation
  setCategoriesList(categories: Dictionary<Suggestion[]>): void {
    this.categoriesList = categories;
  }

  @Action
  async updateCategoriesList(): Promise<Dictionary<Suggestion[]>> {
    const categories = await accountingsService.listCategories({
      accountingPeriodId: accountingPeriodsStore.currentId,
    });
    this.setCategoriesList(categories);
    return categories;
  }

  get getCategoriesListWithoutDisabled() {
    return groupBy(
      flatMap(this.categoriesList).filter((categorie) => !!categorie.display),
      "parent"
    );
  }

  get getCategoriesListWithDoublyEntry() {
    return groupBy(
      flatMap(this.categoriesList).filter(
        (categorie) =>
          OperationAccrualLib.getDoubleEntryAccount(categorie.number) !==
          LedgerAccountEnum.UNKNOWN
      ),
      "parent"
    );
  }
}
