






































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
} from "@vue/composition-api";
import { AccrualState } from "../accruals/accruals.usable";
import { useCategorization } from "./categorization.usable";
import CategorizationStep1 from "./CategorizationStep1.vue";
import CategorizationStep2 from "./CategorizationStep2.vue";

export default defineComponent({
  name: "Categorization",
  props: {
    accrualState: {
      type: Object as PropType<AccrualState>,
      required: true,
    },
  },
  components: {
    CategorizationStep1,
    CategorizationStep2,
  },
  setup(props, context) {
    const accrualState = computed(() => props.accrualState);
    const categorizationUse = computed(() =>
      useCategorization(accrualState.value, context)
    );
    const summary: ComputedRef = computed(() => {
      return accrualState.value.summary;
    });

    const closeCategorizationDetails = () => {
      categorizationUse.value.resetCategories();
      categorizationUse.value.isOpenCategorizationDetailStep.value = false;
      categorizationUse.value.isOpenCategorizationList.value = false;
    };

    return {
      summary,
      closeCategorizationDetails,
    };
  },
});
