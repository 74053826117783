export enum TaskCode {
  ProductCreate = "product.create",
  EmailAdd = "email.add",
  EmailValidate = "email.validate",
  PhoneAdd = "phone.add",
  PhoneValidate = "phone.validate",
  BankCreate = "bank.create",
  BankCorrect = "bank.correct",
  CreateStatus = "create.status",
  PartnerCreate = "partner.create",
  SiretAdd = "siret.add",
  SiretConfirm = "siret.confirm",
  BankAccountLink = "bank-account.link",
  AssetUpdate = "asset.update",
  AmortisationUpdate = "amortisation.update",
  BalanceSheetRecovery = "balance-sheet-recovery",
  BalanceSheetRecoveryVerify = "balance-sheet-recovery.verify",
  InjectBalance = "inject-balance",
  InjectBalanceVerify = "inject-balance.verify",
  DebitsCreditsRecorded = "debits-credits-recorded",
  PreviousYearAmortisation = "previous-year-amortisation",
  TransactionsCategorized = "transactions-categorized",
  SupportingDocuments = "supporting-documents",
  BalanceSimple = "balance.simple",
  Invite = "invite",
  Report = "report",
  Create = "create",
  InviteYearEnd = "invite.year-end",
  ReportYearEnd = "report.year-end",
  BuildingConfigured = "building-configured",

  FillCerfa2072 = "fill-cerfa-2072",
  UploadCerfa2072 = "upload-cerfa-2072",
  FillCerfa2033 = "fill-cerfa-2033",
  FillCerfa2065 = "fill-cerfa-2065",
  FillCerfa2031 = "fill-cerfa-2031",
  FillFec = "fill-fec",

  CheckPartnersResults = "check-partners-results",
  SendPartnersResults = "send-partners-results",
  EndingBalanceVerify = "ending-balance.verify",
  SubscriptionActive = "subscription.active",
  SubscriptionError = "subscription.error",
  ProductUpdate = "product.update",
  ActivityInformationCompleted = "activity-information.completed",
  LoanAdd = "loan.add",
  ActivityEmailAdd = "activity-email.add",
  FirstTransactionAdd = "first-transaction.add",
  DeferrableAmortisationsAdd = "deferrable-amortisations.add",
  DeferrableDeficitsAdd = "deferrable-deficits.add",
  DeferrableAmortisationsAddVerify = "deferrable-amortisations.add.verify",
  DeferrableDeficitsAddVerify = "deferrable-deficits.add.verify",

  YearEndAmortizationUpdate = "year-end.amortization.update",
  CheckEligibleWorksAreImmobilized = "check.eligible-works.immobilized",
  AmortizationsResumptionPostAmortizationsUpdate = "amortization-resumption.post.amortizations-update",
  ViewAndControlResultsAccount = "year-end.view-control-results-account",
  ValidateAndObtainBalanceSheet = "year-end.validate-and-obtain-balance-sheet",

  TeletransmitMyTaxReturn = "teletransmit-my-tax-return",
  AccrualsInventory = "accruals-inventory"
}
