import { operationsService } from "@/services";
import store from "@/store/root";
import {
  OperationAccrualLib,
  OperationAccrualsModel,
  OperationAccrualsService,
} from "@edmp/api";
import { cloneDeep } from "lodash";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

/**
 * Store for accrual operations.
 */
export interface OperationAccrualsState {
  operationAccruals: OperationAccrualsModel.OperationAccrual[];
  unreconciledOperationAccruals: OperationAccrualsModel.OperationAccrual[];
  loading: boolean;
}

@Module({
  name: "operation-accruals-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class OperationAccrualsStore
  extends VuexModule
  implements OperationAccrualsState
{
  operationAccruals: OperationAccrualsModel.OperationAccrual[] = [];
  unreconciledOperationAccruals: OperationAccrualsModel.OperationAccrual[] = [];
  loading = false;

  get recoveryOperationAccruals(): OperationAccrualsModel.OperationAccrual[] {
    return this.operationAccruals.filter((operationAccrual) =>
      OperationAccrualLib.isOperationRecovery(operationAccrual)
    );
  }

  get recoveryOperationAccrualsByAccountingPeriodId() {
    return (accountingPeriodId: string) => {
      return cloneDeep(this.recoveryOperationAccruals).filter(
        (operationAccrual) =>
          operationAccrual.accountingPeriodId === accountingPeriodId
      );
    };
  }

  get closureOperationAccruals(): OperationAccrualsModel.OperationAccrual[] {
    return this.operationAccruals.filter((operationAccrual) =>
      OperationAccrualLib.isOperationClosure(operationAccrual)
    );
  }

  get closureOperationAccrualsByDoubleEntryAccount() {
    return (account: OperationAccrualsModel.DoubleEntryAccounts) => {
      return cloneDeep(this.closureOperationAccruals).filter(
        (closureOperationAccrual) =>
          closureOperationAccrual.journalEntry.lines?.find(
            (line) =>
              OperationAccrualLib.getDoubleEntryAccount(line.account) ===
              account
          )
      );
    };
  }

  @Mutation
  reset(): void {
    this.loading = false;
    this.operationAccruals = [];
    this.unreconciledOperationAccruals = [];
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  setOperationAccruals(
    operationAccruals: Array<OperationAccrualsModel.OperationAccrual>
  ): void {
    this.operationAccruals = operationAccruals.reverse();
  }

  @Mutation
  setUnreconciledOperationAccruals(
    unreconciledOperationAccruals: Array<OperationAccrualsModel.OperationAccrual>
  ): void {
    this.unreconciledOperationAccruals =
      unreconciledOperationAccruals.reverse();
  }

  @Mutation
  removeOperationAccrual(id: string) {
    const operationIndex = this.operationAccruals.findIndex(
      (operation) => operation.id === id
    );
    if (operationIndex !== -1) {
      this.operationAccruals.splice(operationIndex, 1);
    }
  }

  @Mutation
  removeUnreconciledOperationAccrual(id: string) {
    const operationIndex = this.unreconciledOperationAccruals.findIndex(
      (operation) => operation.id === id
    );
    if (operationIndex !== -1) {
      this.unreconciledOperationAccruals.splice(operationIndex, 1);
    }
  }

  @Action
  async fetchOperationAccruals(
    params: OperationAccrualsService.ListIn
  ): Promise<void> {
    this.setLoading(true);
    this.setOperationAccruals([]);
    try {
      const operationAccruals = await operationsService.accruals.list(params);
      this.setOperationAccruals(operationAccruals);
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  @Action
  async fetchUnreconciledOperationAccruals(
    params: Pick<OperationAccrualsService.ListIn, "productId">
  ): Promise<void> {
    this.setLoading(true);
    this.setUnreconciledOperationAccruals([]);
    try {
      const unreconciledOperationAccruals =
        await operationsService.accruals.list({
          ...params,
          options: { includedReconciled: false },
        });
      this.setUnreconciledOperationAccruals(unreconciledOperationAccruals);
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  @Action
  async create(
    params: OperationAccrualsService.CreateIn
  ): Promise<OperationAccrualsModel.OperationAccrual | undefined> {
    const operationCreated = await operationsService.accruals.create(params);
    return operationCreated;
  }

  @Action
  async reconcile(
    params: OperationAccrualsService.ReconcileIn
  ): Promise<OperationAccrualsModel.OperationAccrual | undefined> {
    const operationReconciled = await operationsService.accruals.reconcile(
      params
    );
    return operationReconciled;
  }

  @Action
  async remove(id: string) {
    const isDeleted = await operationsService.accruals.remove({ id });
    if (isDeleted) {
      this.removeOperationAccrual(id);
      this.removeUnreconciledOperationAccrual(id);
    }
  }
}
