






















































































































import DatePicker from "@/components/atom/DatePicker.vue";
import { VForm } from "@/models";
import {
  accountingPeriodsStore,
  coreStore,
  documentsStore,
  productsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import {
  DocumentCreate,
  DocumentTag,
  getMoment,
  LedgerAccountEnum,
  Suggestion,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { useAccountingPeriod } from "../accounting-period/AccountingPeriod.usable";

export default defineComponent({
  name: "AddSupportingDocumentsModal",
  components: {
    DatePicker,
  },
  props: {
    smallDisplay: {
      type: Boolean,
      default: false,
    },
    selectedCategory: {
      type: Object as PropType<Suggestion>,
      required: false,
    },
    defaultDate: {
      type: String,
      required: false,
    },
    minDate: {
      type: String,
      required: false,
    },
    maxDate: {
      type: String,
      required: false,
    },
    recovery: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const file: Ref<File | null> = ref(null);
    const issuanceDate: Ref<string> = ref(
      props.defaultDate ? props.defaultDate : props.maxDate ?? ""
    );
    const wording: Ref<string> = ref("");
    const amountTotal: Ref<number> = ref(0);
    const amount: Ref<number> = ref(0);
    const amountTax = computed(() =>
      amountTotal.value && amount.value
        ? Number(new Decimal(amountTotal.value - amount.value).toFixed(2))
        : 0
    );

    const { getAccountingPeriodList } = useAccountingPeriod();

    const today = computed(() => getMoment().format("YYYY-MM-DD"));

    const acquisitionValidate = (): boolean => {
      if (props.selectedCategory?.number === LedgerAccountEnum.N213000) {
        return getMoment(issuanceDate.value).isBetween(
          accountingPeriodsStore.currentAccountingPeriod?.startAt,
          accountingPeriodsStore.currentAccountingPeriod?.endAt,
          undefined,
          "[]"
        );
      }
      return true;
    };

    const validate = async (e: Event) => {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        const accountPeriodLinked = (await getAccountingPeriodList())
          .filter((period) =>
            getMoment(period.startAt).isSameOrBefore(
              getMoment(issuanceDate.value)
            )
          )
          .sort((a, b) => getMoment(a.endAt).diff(getMoment(b.endAt)))[0];

        if (!props.recovery && !accountPeriodLinked) {
          throw new Error("Pas de période comptable compatible");
        }

        const infos: DocumentCreate = {
          tags: ["supportingDocument" as DocumentTag],
          metadata: {
            wording: wording.value,
            issuanceDate: issuanceDate.value,
            amountTotal: amountTotal.value,
            amount: amount.value,
            amountTax: amountTax.value,
          },
          product: {
            id: productsStore.currentId,
            accountingPeriod: { id: accountPeriodLinked?.id },
          },
        };
        try {
          if (!file.value) {
            throw new Error("Fichier manquant");
          }

          if (!productsStore.currentId) {
            throw new Error("Pas de SCI sélectionnée");
          }

          const document = await documentsStore.createDocument({
            document: infos,
            file: file.value,
          });

          coreStore.displayFeedback({
            message: "Votre justificatif a bien été enregistré sur Ownily.",
          });
          context.emit("documentAdded", document.id);
          context.emit("close");
        } catch (err) {
          console.error(err);
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: "Un problème est survenu lors de l'ajout du document.",
          });
        }
      }
    };

    const amountValidate = (amount: number) => {
      return amount.toString().length <= new Decimal(amount).toFixed(2).length;
    };

    return {
      acquisitionValidate,
      amountValidate,
      today,
      file,
      issuanceDate,
      wording,
      amountTotal,
      amount,
      amountTax,
      validate,
    };
  },
});
