import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { JWTRedirectionBIUser } from "./Bank.model";

export interface SynchroStats {
  accounts: number;
  newTransactions: number;
  transactions: number;
  provider: string;
  timestamp: string;
}

export interface LandingStats {
  timestamp: string;
  productId?: string; // Parameter to synchronize BankAccount with Company in webview
  redirectId: string;
  state: JWTRedirectionBIUser;
}

export interface BankStats {
  id: string;
  userId: string;
  bankId: string;
  synchronize: SynchroStats[];
  landings: LandingStats[];
}

export const SynchroStatsSchema = new Schema<SynchroStats>({
  accounts: Number,
  newTransactions: Number,
  transactions: Number,
  provider: String,
  timestamp: String,
});

export const BankStatsSchema = new Schema<BankStatsDocument>(
  {
    _id: { type: String, default: () => ulid() },
    userId: { type: String, index: true },
    synchronize: [SynchroStatsSchema],
    landings: [
      {
        timestamp: String,
        productId: String,
        redirectId: String,
        state: {
          sub: String,
          provider: String,
          productId: String,
          iat: Number,
          exp: Number,
        },
      },
    ],
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: BankStatsDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type BankStatsDocument = BankStats & Document<string>;

// Name of the collection in third argument
export const BankStatsModel = model<BankStatsDocument>("BankStats", BankStatsSchema, "BanksStats");
