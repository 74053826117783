



























































































































































































































































































































import { countDecimals } from "@/utils";
import { CheckNewAnomaliesType, LedgerAccountEnum } from "@edmp/api";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import Anomalies from "../../anomalies/Anomalies.vue";
import { AccrualState } from "../accruals/accruals.usable";
import { useCategorization } from "./categorization.usable";

export default defineComponent({
  name: "CategorizationStep2",
  components: {
    Anomalies,
  },
  props: {
    accrualState: {
      type: Object as PropType<AccrualState>,
      required: true,
    },
  },
  setup(props, context) {
    const accrualState = computed(() => props.accrualState);
    const categorizationUse = computed(() =>
      useCategorization(accrualState.value, context)
    );
    const hasCharges = computed(() => {
      const charges = accrualState.value.lines.find(
        (line) => line.account === LedgerAccountEnum.N708399
      );
      if (charges) {
        return true;
      }
      return false;
    });

    const validate = async () => {
      categorizationUse.value.saveCategorization();
      context.emit("validate");
    };

    return {
      categorizationUse,
      CheckNewAnomaliesType,
      countDecimals,
      validate,
      hasCharges,
    };
  },
});
