import { AccrualAttribute, AccrualItem } from "@/models";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  documentsStore,
  fixedAssetsStore,
  operationAccrualsStore,
  partnersStore,
  productsStore,
  realEstateAssetsStore,
  realEstateLoansStore,
  rentalAgreementsStore,
  tasksStore,
} from "@/store";
import {
  AccountingBalanceSheet,
  AccountingPeriod,
  CategorizationEntry,
  Document,
  getMoment,
  getReferredIdByTypeWithReferences,
  JournalEntryReference,
  LedgerAccountEnum,
  OperationAccrualLib,
  OperationAccrualsModel,
  ProductsModel,
  Suggestion,
  TaskCode,
  TaskTypeReference,
  Transaction,
  TypeReference,
} from "@edmp/api";
import { computed, ComputedRef, Ref, ref } from "@vue/composition-api";
import Decimal from "decimal.js-light";
import Moment from "moment";

export interface AccrualState {
  amount: number;
  date: string;
  summary: string;

  lines: CategorizationEntry[]; // lines, categories used in edition in Categorization modals
  selectedCategory?: Suggestion;

  // Creation period
  startAt: string;
  endAt: string;

  transaction?: Transaction; // transaction from reconciliation module

  isOpenCategorizationList: boolean;
  isOpenCategorizationDetailStep: number | false;
  isUpdatingCategorization: boolean; // boolean used when saving categorization to display loading bar
  isCategorized: boolean;
  type?:
    | OperationAccrualsModel.Tags.RECOVERY
    | OperationAccrualsModel.Tags.CLOSURE;
}

export function useAccruals() {
  const accrualItems: Ref<AccrualItem[]> = ref([]);
  const unreconciledAccrualItems: Ref<AccrualItem[]> = ref([]);

  const product = computed(
    () => productsStore.currentProduct as ProductsModel.Product
  );

  const initAccrualItems = async () => {
    try {
      if (product.value) {
        await documentsStore.fetchDocuments();

        const tempAccrualItems: AccrualItem[] = [];
        for (const operation of operationAccrualsStore.operationAccruals) {
          tempAccrualItems.push(getAccrualItem(operation));
        }

        accrualItems.value = tempAccrualItems;
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  const initUnreconciledAccrualItems = async () => {
    try {
      if (product.value) {
        await documentsStore.fetchDocuments();

        const tempUnreconciledAccrualItems: AccrualItem[] = [];
        for (const unreconciledOperation of operationAccrualsStore.unreconciledOperationAccruals) {
          tempUnreconciledAccrualItems.push(
            getAccrualItem(unreconciledOperation)
          );
        }

        unreconciledAccrualItems.value = tempUnreconciledAccrualItems;
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  const getAccrualItem = (
    operationAccrual: OperationAccrualsModel.OperationAccrual
  ): AccrualItem => {
    const lines = operationAccrual.journalEntry.lines?.filter(
      (line) => line.account !== LedgerAccountEnum.N512000
    );
    const accrualItem: AccrualItem = {
      operationId: operationAccrual.id,
      reportedLoss: operationAccrual.reportedLoss ?? false,
      reconciliation: operationAccrual.reconciliation,
      kinds: Array.from(
        new Set(lines?.map((line) => line.accountName ?? "") || [])
      ),
      description: operationAccrual.journalEntry.description || "",
      amount: OperationAccrualLib.getAmount(operationAccrual),
      attributes: Array.from(
        new Map(
          (lines?.flatMap((line) => getAttributes(line.refs || [])) || []).map(
            (attribute) => [attribute.id, attribute]
          )
        ).values()
      ),
      date: operationAccrual.journalEntry.date,
      invoices: Array.from(
        new Set(
          (lines?.map((line) => getInvoice(line.refs || [])) || []).filter(
            (invoice): invoice is Document => invoice !== undefined
          )
        )
      ),
      year: getYearLabel(operationAccrual),
    };
    return accrualItem;
  };

  const getYearLabel = (
    operationAccrual: OperationAccrualsModel.OperationAccrual
  ) => {
    if (OperationAccrualLib.isOperationRecovery(operationAccrual)) {
      let year: String;
      if (hasCompletedTaxTeletransmitTask2023()) {
        year = "2024";
      } else {
        year = String(
          new Date(
            accountingPeriodsStore.firstAccountingPeriod.endAt
          ).getFullYear()
        );
      }
      return `Avant ${year}`;
    } else {
      return String(new Date(operationAccrual.journalEntry.date).getFullYear());
    }
  };

  const getAttributes = (
    references: JournalEntryReference[]
  ): AccrualAttribute[] => {
    const attributes: AccrualAttribute[] = [];
    for (const reference of references) {
      const attribute: AccrualAttribute = {
        type: reference.type,
        id: reference.referredId,
      };
      if (attribute) {
        attributes.push(attribute);
      }
    }
    return attributes;
  };

  const MISSING_VALUE = "Ø";
  const getAttributeDisplay = (
    attribute: AccrualAttribute
  ): { icon: string; name: string } => {
    if (attribute.type === TypeReference.realEstateAsset) {
      const realEstateAsset = realEstateAssetsStore.realEstateAssets.find(
        (realEstateAsset) => realEstateAsset.id === attribute.id
      );
      return realEstateAsset
        ? { icon: "mdi-home-outline", name: realEstateAsset.name }
        : { icon: "", name: MISSING_VALUE };
    }

    if (attribute.type === TypeReference.rentalAgreement) {
      const rentalAgreement = rentalAgreementsStore.rentalAgreements.find(
        (rentalAgreement) => rentalAgreement.id === attribute.id
      );
      return rentalAgreement
        ? { icon: "mdi-account-key-outline", name: rentalAgreement.name }
        : { icon: "", name: MISSING_VALUE };
    }

    if (attribute.type === TypeReference.partner) {
      return {
        icon: "mdi-account-outline<",
        name: partnersStore.getPartnerName(attribute.id) || MISSING_VALUE,
      };
    }

    if (attribute.type === TypeReference.realEstateLoan) {
      const realEstateLoan = realEstateLoansStore.realEstateLoans.find(
        (realEstateLoan) => realEstateLoan.id === attribute.id
      );
      return realEstateLoan
        ? { icon: "mdi-home-percent-outline", name: realEstateLoan.name }
        : { icon: "", name: MISSING_VALUE };
    }

    if (attribute.type === TypeReference.fixedAsset) {
      const fixedAsset = fixedAssetsStore.fixedAssets.find(
        (fixedAsset) => fixedAsset.id === attribute.id
      );
      return fixedAsset
        ? { icon: "mdi-home-clock-outline", name: fixedAsset.name }
        : { icon: "", name: MISSING_VALUE };
    }

    return { icon: "", name: MISSING_VALUE };
  };

  const getInvoice = (
    references: JournalEntryReference[]
  ): Document | undefined => {
    const supportingDocumentReference = references.find(
      (ref) => ref.type === TypeReference.supportingDocument
    );
    if (supportingDocumentReference) {
      return documentsStore.getDocument(supportingDocumentReference.referredId);
    }
    return undefined;
  };

  const initAccrualState = (
    accountingPeriod: AccountingPeriod,
    type?:
      | OperationAccrualsModel.Tags.RECOVERY
      | OperationAccrualsModel.Tags.CLOSURE,
    transaction?: Transaction | undefined
  ): AccrualState => {
    let startAt = accountingPeriod.startAt;
    let endAt = accountingPeriod.endAt;
    if (type === OperationAccrualsModel.Tags.RECOVERY) {
      if (hasCompletedTaxTeletransmitTask2023()) {
        const accountingPeriod2023 =
          accountingPeriodsStore.accountingPeriods.find(
            (accountingPeriod) =>
              getMoment(accountingPeriod.endAt).year() === 2023
          ) as AccountingPeriod;
        startAt = accountingPeriod2023.startAt;
        endAt = accountingPeriod2023.endAt;
      } else {
        const previousBalanceSheet: ComputedRef<AccountingBalanceSheet> =
          computed(
            () =>
              accountingBalanceSheetsStore.getFirstAccountingBalanceSheetByRecovery as AccountingBalanceSheet
          );
        if (previousBalanceSheet.value.type === "recovery") {
          startAt = previousBalanceSheet.value.startAt;
          endAt = previousBalanceSheet.value.endAt;
        }
      }
    }
    return {
      amount: transaction
        ? new Decimal(transaction.value.amount).abs().toNumber()
        : 0,
      date: transaction
        ? new Date(endAt) > new Date(transaction.date.operation)
          ? transaction.date.operation
          : endAt
        : Moment().isBetween(Moment(startAt), Moment(endAt))
        ? Moment().format("YYYY-MM-DD")
        : Moment(startAt).format("YYYY-MM-DD"),
      summary: "",
      lines: [] as CategorizationEntry[],
      transaction,
      isOpenCategorizationList: false,
      isOpenCategorizationDetailStep: false,
      isUpdatingCategorization: false,
      isCategorized: false,
      type,
      startAt,
      endAt: transaction
        ? new Date(endAt) > new Date(transaction.date.operation)
          ? transaction.date.operation
          : endAt
        : endAt,
    };
  };

  const hasCompletedTaxTeletransmitTask2023 = () =>
    tasksStore.completedTasks.some(
      (task) =>
        task.code === TaskCode.TeletransmitMyTaxReturn &&
        accountingPeriodsStore.accountingPeriods.some(
          (accountingPeriod) =>
            getMoment(accountingPeriod.endAt).year() === 2023 &&
            getReferredIdByTypeWithReferences(
              task.references,
              TaskTypeReference.accountingPeriod
            ) === accountingPeriod.id
        )
    );

  return {
    accrualItems,
    unreconciledAccrualItems,
    initAccrualItems,
    initUnreconciledAccrualItems,
    initAccrualState,
    MISSING_VALUE,
    getAttributeDisplay,
    hasCompletedTaxTeletransmitTask2023,
  };
}
