
























































































































































































































































































































































































































































































































































































































































import { YesNoSwitch } from "@/components/atom/switch";
import Next from "@/components/core/modals/Next.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { VConfirmDialog, VForm } from "@/models";
import { ability } from "@/services";
import {
  accountingPeriodsStore,
  coreStore,
  fixedAssetsStore,
  productsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  tenantsStore,
  subscriptionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ForbiddenError, subject } from "@casl/ability";
import {
  CategorizationEntry,
  LedgerAccountEnum,
  TaxRateTva,
  TenantTypeEnum,
  TypeReference,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { cloneDeep } from "lodash";
import RealEstateLoanCreate from "../../activities/realEstateLoans/RealEstateLoanModal.vue";
import DialogRight from "../../DialogRight.vue";
import FixedAssetCreate from "../../fixedAssets/FixedAssetForm.vue";
import RealEstateAssetCreate from "../../realEstate/RealEstateForm.vue";
import SupportingDocumentCreate from "../../supportingDocuments/AddSupportingDocumentsModal.vue";
import TenantCreate from "../create/TenantCreate.vue";
import { TransactionState } from "../transaction/transaction.usable";
import { useCategorization } from "./categorization.usable";
import { CategoryValidate } from "./categorizationValidateCategories.usable";

export default defineComponent({
  name: "CategorizationStep1",
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
  },
  components: {
    YesNoSwitch,
    DialogRight,
    Next,
    RealEstateAssetCreate,
    FixedAssetCreate,
    TenantCreate,
    SupportingDocumentCreate,
    RealEstateLoanCreate,
  },
  setup(props, context) {
    const subscription = computed(() =>
      subscriptionsStore.getSubscriptionByProduct(productsStore.currentId)
    );

    const categorizationUse = computed(() =>
      useCategorization(props.transactionState, context)
    );
    /**
     * References
     */
    const firstCategorization: CategorizationEntry =
      props.transactionState.lines[0];

    const realEstateAssetFirstChoice = firstCategorization?.realEstateAsset;
    const fixedAssetFirstChoice = firstCategorization?.fixedAsset;
    const partnerFirstChoice = firstCategorization?.partner;
    const realEstateLoanFirstChoice = firstCategorization?.realEstateLoan;

    const fixedAssets = computed(() => categorizationUse.value.fixedAssets());
    const fixedAsset = reactive<{
      show: boolean;
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
      create: boolean;
    }>({
      show:
        categorizationUse.value.isRequired(TypeReference.fixedAsset) ||
        categorizationUse.value.isOptional(TypeReference.fixedAsset),
      list: computed(() => fixedAssets.value),
      selected: fixedAssetFirstChoice
        ? fixedAssets.value.find(
            (fixedAsset) => fixedAsset.id === fixedAssetFirstChoice
          )
        : fixedAssets.value[0],
      create: false,
    });

    const realEstateAssets = computed(() =>
      categorizationUse.value.realEstateAssets()
    );
    const realEstateAsset = reactive<{
      show: boolean;
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
      create: boolean;
    }>({
      show:
        categorizationUse.value.isRequired(TypeReference.realEstateAsset) ||
        categorizationUse.value.isOptional(TypeReference.realEstateAsset),
      list: computed(() => realEstateAssets.value),
      selected: realEstateAssetFirstChoice
        ? realEstateAssets.value.find(
            (realEstateAsset) =>
              realEstateAsset.id === realEstateAssetFirstChoice
          )
        : realEstateAssets.value[0],
      create: false,
    });
    watch(
      () => fixedAsset.list,
      () => {
        fixedAsset.selected = fixedAssets.value[0];
      }
    );
    watch(
      () => realEstateAsset.list,
      () => {
        realEstateAsset.selected = realEstateAssets.value[0];
      }
    );

    const rentalUnit = computed<
      | {
          name: string;
          id: string;
          taxTvaEnable?: boolean;
          taxRateTVA?: TaxRateTva;
        }
      | undefined
    >(() => {
      if (realEstateAsset.selected?.id) {
        return realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
          realEstateAsset.selected.id
        );
      }
      return undefined;
    });

    const rentalAgreements = computed(() => {
      const rentalAgreements = realEstateAsset.selected
        ? rentalAgreementsStore
            .getRentalAgreementsByRealEstateAssetId(realEstateAsset.selected.id)
            .map((rentalAgreement) => {
              return {
                name: rentalAgreement.name,
                id: rentalAgreement.id as string,
              };
            })
        : [];
      if (categorizationUse.value.isOptional(TypeReference.rentalAgreement)) {
        return [{ name: "Aucun", id: "" }, ...rentalAgreements];
      }
      return rentalAgreements;
    });
    const rentalAgreement = reactive<{
      show: boolean;
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
      create: boolean;
    }>({
      show:
        categorizationUse.value.isRequired(TypeReference.rentalAgreement) ||
        categorizationUse.value.isOptional(TypeReference.rentalAgreement),
      list: computed(() => rentalAgreements.value),
      selected: rentalAgreements.value[0],
      create: false,
    });
    watch(
      () => rentalAgreement.list,
      () => {
        rentalAgreement.selected = rentalAgreements.value[0];
      }
    );

    const tenants = computed(() => {
      const tenants = realEstateAsset.selected
        ? tenantsStore
            .getTenantsByRealEstateAssetId(realEstateAsset.selected.id)
            .map((tenant) => {
              if (tenant.type === TenantTypeEnum.NATURAL_PERSON) {
                return {
                  name: `${tenant.firstName} ${tenant.lastName}`,
                  id: tenant.id as string,
                };
              } else {
                return {
                  name: `${tenant.denomination}`,
                  id: tenant.id as string,
                };
              }
            })
        : [];
      if (categorizationUse.value.isOptional(TypeReference.tenant)) {
        return [{ name: "Aucun", id: "" }, ...tenants];
      }
      return tenants;
    });
    const tenant = reactive<{
      show: boolean;
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
      create: boolean;
    }>({
      show:
        categorizationUse.value.isRequired(TypeReference.tenant) ||
        categorizationUse.value.isOptional(TypeReference.tenant),
      list: computed(() => tenants.value),
      selected: tenants.value[0],
      create: false,
    });
    watch(
      () => tenant.list,
      () => {
        tenant.selected = tenants.value[0];
      }
    );

    const partners = computed(() => categorizationUse.value.partners());
    const partner = reactive<{
      show: boolean;
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
    }>({
      show:
        categorizationUse.value.isRequired(TypeReference.partner) ||
        categorizationUse.value.isOptional(TypeReference.partner),
      list: computed(() => {
        if (categorizationUse.value.isOptional(TypeReference.partner))
          return [{ name: "Aucun", id: "" }, ...partners.value];
        return partners.value;
      }),
      selected: categorizationUse.value.isRequired(TypeReference.partner)
        ? partnerFirstChoice
          ? partners.value.find((partner) => partner.id === partnerFirstChoice)
          : partners.value[0]
        : partnerFirstChoice
        ? partners.value.find((partner) => partner.id === partnerFirstChoice)
        : { name: "Aucun", id: "" },
    });

    const loans = computed(() =>
      categorizationUse.value.loans(realEstateAsset.selected?.id)
    );

    const realEstateLoan = reactive({
      show:
        categorizationUse.value.isRequired(TypeReference.realEstateLoan) ||
        categorizationUse.value.isOptional(TypeReference.realEstateLoan),
      list: computed(() => loans.value),

      selected: realEstateLoanFirstChoice
        ? loans.value.find((loan) => loan.id === realEstateLoanFirstChoice)
        : loans.value[0],
      create: false,
    });

    // In case of switch of Products we have a new list of realEstateLoan
    watch(
      () => loans.value,
      () => {
        realEstateLoan.selected =
          realEstateLoan.list[realEstateLoan.list.length - 1];
      }
    );

    const supportingDocuments = computed(() =>
      categorizationUse.value.supportingDocuments()
    );
    const supportingDocument = reactive({
      show:
        categorizationUse.value.isRequired(TypeReference.supportingDocument) ||
        categorizationUse.value.isOptional(TypeReference.supportingDocument),
      list: supportingDocuments,
      selected: (() => {
        if (
          categorizationUse.value.isOptional(TypeReference.supportingDocument)
        ) {
          if (
            props.transactionState.selectedCategory?.number ===
              LedgerAccountEnum.N706000 ||
            (accountingPeriodsStore.isLMNP &&
              categorizationUse.value.getSupportingDocuments.value[0]
                ?.amount !== props.transactionState.transaction.value.amount)
          ) {
            return supportingDocuments.value[0];
          }
        }
        return categorizationUse.value.getSupportingDocuments.value[0];
      })(),
      create: false,
    });

    const beneficiaries = computed(() => {
      const beneficiaries = productsStore.products
        .filter((product) => product.id !== productsStore.currentId)
        .map((p) => ({ name: p.name, id: p.id }));
      if (categorizationUse.value.isOptional(TypeReference.beneficiary)) {
        return [{ name: "Aucun", id: "" }, ...beneficiaries];
      }
      return beneficiaries;
    });

    const beneficiary = reactive<{
      show: boolean;
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
    }>({
      show:
        accountingPeriodsStore.isIS &&
        props.transactionState.selectedCategory?.number === "508000",
      list: beneficiaries,
      selected: beneficiaries.value[0],
    });

    /**
     * Optionals Categories
     */
    // Rent collected (706000)
    const withRentalCharge: Ref<boolean> = ref(false);
    const rentalChargeAmount: Ref<number> = ref(0);
    const rentalChargeAmountFixed = computed<string>({
      set: (value) => {
        if (value) {
          rentalChargeAmount.value = Number(Number(value).toFixed(2));
        }
      },
      get: () => {
        return rentalChargeAmount.value.toString();
      },
    });

    // Loan Capital borrowed or reimbursed (164000)
    const withInterestsAndInsurance: Ref<boolean> = ref(false);
    const interestsAndInsuranceAmount: Ref<number> = ref(0);
    function onChangeInterestsAndInsurance(value: boolean) {
      withInterestsAndInsurance.value = value;
    }
    const interestsAndInsuranceAmountFixed = computed<string>({
      set: (value) => {
        if (value) {
          interestsAndInsuranceAmount.value = Number(Number(value).toFixed(2));
        }
      },
      get: () => {
        return interestsAndInsuranceAmount.value.toString();
      },
    });

    /**
     * Actions
     */
    async function validateAttributions() {
      if ((context.refs.form as VForm).validate()) {
        if (props.transactionState.selectedCategory) {
          const categoryValidate: CategoryValidate = {
            category: cloneDeep(props.transactionState.selectedCategory),
            references: {
              realEstateAsset:
                realEstateAsset.selected?.id && realEstateAsset.show
                  ? realEstateAsset.selected?.id
                  : undefined,
              rentalUnit: rentalUnit.value?.id ?? undefined,
              rentalAgreement:
                rentalAgreement.selected?.id && rentalAgreement.show
                  ? rentalAgreement.selected?.id
                  : undefined,
              tenant:
                tenant.selected?.id && tenant.show
                  ? tenant.selected?.id
                  : undefined,
              partner:
                partner.selected?.id && partner.show
                  ? partner.selected?.id
                  : undefined,
              realEstateLoan:
                realEstateLoan.selected?.id && realEstateLoan.show
                  ? realEstateLoan.selected?.id
                  : undefined,
              supportingDocument:
                supportingDocument.selected?.id && supportingDocument.show
                  ? supportingDocument.selected?.id
                  : undefined,
              fixedAsset:
                fixedAsset.selected?.id && fixedAsset.show
                  ? fixedAsset.selected?.id
                  : undefined,
              beneficiary:
                beneficiary.selected?.id && beneficiary.show
                  ? beneficiary.selected?.id
                  : undefined,
            },
            optionalsCategories: new Map<LedgerAccountEnum, number>(),
          };

          if (withRentalCharge.value) {
            categoryValidate.optionalsCategories.set(
              LedgerAccountEnum.N708399,
              rentalChargeAmount.value
            );
          }
          if (withInterestsAndInsurance.value) {
            categoryValidate.optionalsCategories.set(
              LedgerAccountEnum.N661100,
              interestsAndInsuranceAmount.value
            );
          }

          await categorizationUse.value.validateCategory(
            categoryValidate,
            context.refs.confirmDialog as VConfirmDialog
          );
          categorizationUse.value.isOpenCategorizationDetailStep.value = 2;
        }
      }
    }

    async function subDivide() {
      await validateAttributions();
      await categorizationUse.value.subDivide();
    }

    const changeCategory = () => {
      categorizationUse.value.isOpenCategorizationList.value = true;
      categorizationUse.value.isOpenCategorizationDetailStep.value = false;
    };

    const createRealEstateAsset = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "addRealEstateAsset",
          subject("RentalManagement", {
            size: realEstateAssetsStore.realEstateAssets.length,
          })
        );
        realEstateAsset.create = true;
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };

    onMounted(() => {
      if (!props.transactionState.selectedCategory?.fields?.length) {
        validateAttributions();
      }
      fixedAssetsStore.fetchFixedAssets(productsStore.currentId);
    });
    return {
      categorizationUse,
      withRentalCharge,
      rentalChargeAmountFixed,
      onChangeInterestsAndInsurance,
      withInterestsAndInsurance,
      interestsAndInsuranceAmountFixed,
      fixedAsset,
      realEstateAsset,
      rentalUnit,
      rentalAgreement,
      tenant,
      partner,
      realEstateLoan,
      loans,
      supportingDocument,
      beneficiary,
      TypeReference,
      LedgerAccountEnum,
      validateAttributions,
      subDivide,
      Decimal,
      changeCategory,
      createRealEstateAsset,
      openArticleFixedAsset: () =>
        useCrisp().openArticle(ArticleEnum.FIXED_ASSETS_RECOMMENDATION),
      subscription,
      accountingPeriodsStore,
      realEstateAssetsStore,
    };
  },
});
