
































































































import EditableTable, {
  EditableTableRef,
  TableHeaderItems,
} from "@/components/atom/table/EditableTable.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import { AccrualItem, VConfirmDialog } from "@/models";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  operationAccrualsStore,
  transactionsStore,
} from "@/store";
import { FilterKeys } from "@/utils";
import { OperationAccrualLib } from "@edmp/api";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { extension } from "mime-types";
import moment from "moment";
import { useAccruals } from "./accruals.usable";

export default defineComponent({
  name: "AccrualsTable",
  components: {
    EditableTable,
    Confirm,
  },
  props: {
    accrualItems: {
      type: Array as PropType<AccrualItem[]>,
      required: true,
    },
    viewType: {
      type: String as PropType<"readOnly" | "editable">,
      default: "editable",
    },
  },
  setup(props, context) {
    const viewTypeTable = ref(props.viewType);
    const headers: TableHeaderItems = [
      { text: "Statut", value: "reconciliation" },
      { text: "Date", value: "date" },
      { text: "Nature de l'engagement", value: "kinds" },
      { text: "Description", value: "description" },
      { text: "Montant", value: "amount" },
      { text: "Attributs", value: "attributes" },
      { text: "Facture", value: "invoices" },
      ...(viewTypeTable.value === "readOnly"
        ? [{ text: "Exercice", value: "year" }]
        : []),
    ];
    const items = computed<AccrualItem[]>(() => {
      return [...props.accrualItems].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      });
    });

    const initTable = () => {
      for (let index = 0; index < items.value.length; index++) {
        if (viewTypeTable.value === "readOnly") {
          (context.refs.editableTable as EditableTableRef).updateHideAction(
            index,
            { delete: true }
          );
          return;
        }
        if (
          items.value[index].reconciliation ||
          items.value[index].reportedLoss
        ) {
          (context.refs.editableTable as EditableTableRef).updateHideAction(
            index,
            { delete: true }
          );
        } else {
          (context.refs.editableTable as EditableTableRef).updateHideAction(
            index,
            { delete: false }
          );
        }
      }
    };

    watch(
      () => props.accrualItems,
      () => {
        initTable();
      },
      { deep: true }
    );

    const navigateToTransactions = async (accrualItem: AccrualItem) => {
      const { reconciliation, description } = accrualItem;
      const accountingPeriod = accountingPeriodsStore.currentAccountingPeriod;

      if (reconciliation && reconciliation.transaction && reconciliation.date) {
        const { transaction, date } = reconciliation;

        if (
          accountingPeriod &&
          !OperationAccrualLib.isDateWithinAccountingPeriod(
            date,
            accountingPeriod
          )
        ) {
          const accountingPeriodMatched =
            accountingPeriodsStore.accountingPeriods.find((acc) =>
              moment(date).isBetween(acc.startAt, acc.endAt, "day", "[]")
            );

          if (accountingPeriodMatched) {
            await accountingPeriodsStore.switchAccountingPeriod(
              accountingPeriodMatched.id
            );
          }
        }

        transactionsStore.resetFilter();
        transactionsStore.addFilter({
          code: FilterKeys.ACCRUAL,
          filterParams: {
            id: transaction.id,
            label: description,
          },
        });

        context.root.$router.push({ name: ROUTE_NAMES.Transactions });
      }
    };

    const deleteItem = async ({ rowIndex }: { rowIndex: number }) => {
      const accrualToDelete = props.accrualItems[rowIndex];

      const openConfirm: boolean = await (
        context.refs.confirmDialog as VConfirmDialog
      ).open(
        `Êtes-vous sûr de vouloir supprimer l'engagement ${accrualToDelete.description} ?`
      );

      if (openConfirm) {
        await operationAccrualsStore.remove(accrualToDelete.operationId);
        context.emit("accrualDeleted");
      }
    };
    const { MISSING_VALUE, getAttributeDisplay } = useAccruals();

    const filteredAttributes = (attributes) => {
      return attributes.filter(
        (attribute) => getAttributeDisplay(attribute).name !== MISSING_VALUE
      );
    };

    onMounted(() => {
      initTable();
    });

    return {
      extension,
      headers,
      items,
      deleteItem,
      navigateToTransactions,
      getAttributeDisplay,
      MISSING_VALUE,
      filteredAttributes,
    };
  },
});
