











































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";

import { TransactionState } from "./transaction.usable";
import { useCategorization } from "../categorization/categorization.usable";
import { cloneDeep } from "lodash";
import {
  LedgerAccountEnum,
  TransactionImportType,
  TransactionImportTypeUser,
  TransactionType,
} from "@edmp/api";
import RentReceipt from "../../rent/RentReceipt.vue";
import { accountingPeriodsStore, documentsStore } from "@/store";
import Decimal from "decimal.js-light";
import Confirm from "@/components/core/modals/Confirm.vue";
import { VConfirmDialog } from "@/models";

export default defineComponent({
  name: "TransactionActions",
  components: { RentReceipt, Confirm },
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    const categorizationUse = computed(() =>
      useCategorization(props.transactionState, context)
    );
    const transactionIsRent = computed(() => {
      // To secure, get the first line with Rent Receipt

      const test = props.transactionState?.lines?.find(
        ({ account, amount }) =>
          account.startsWith(LedgerAccountEnum.N706000) &&
          new Decimal(amount).isPositive()
      );
      if (test) {
        return true;
      } else return false;
    });

    const showErrorModal: Ref<boolean> = ref(false);

    const computedSuggestionCategories = computed(() =>
      cloneDeep(props.transactionState.suggestedLines)
    );
    const isOpenRentReceiptStep: Ref<number> = ref(0);

    const attachments = computed(() =>
      documentsStore.getDocumentByTransaction(
        props.transactionState.transaction.id
      )
    );

    const transactionDontHaveReceipt = computed(() => {
      if (attachments.value) {
        let exist = attachments.value;

        exist = attachments.value.filter((doc) => {
          if (doc.tags) {
            if (doc.tags.includes("receipt")) {
              return doc;
            }
          }
        });
        if (exist.length > 0) {
          return false;
        }
      }

      return true;
    });

    const isExpenseReport = (type: TransactionType) =>
      type === TransactionImportTypeUser.EXPENSE_REPORT;
    const isAmortization = (type: TransactionType) =>
      type === TransactionImportType.AMORTIZATION;
    const isAcquisition = (type: TransactionType) =>
      type === TransactionImportType.ACQUISITION;

    const validateSuggestion = async () => {
      if (categorizationUse.value.checkOpenReconciliation()) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(`Souhaitez-vous réconcilier avec un engagement ?`);
        if (openConfirm) {
          categorizationUse.value.isOpenReconciliation.value = true;
          return;
        }
      }
      categorizationUse.value.validateSuggestion();
    };

    return {
      transactionDontHaveReceipt,
      transactionIsRent,
      isOpenRentReceiptStep,
      categorizationUse,
      computedSuggestionCategories,
      showErrorModal,
      isExpenseReport,
      isAmortization,
      isAcquisition,
      validateSuggestion,
      isIS: accountingPeriodsStore.isIS,
      isLMNP: accountingPeriodsStore.isLMNP,
    };
  },
});
